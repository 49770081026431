import React from "react";
import "./hero.css";

function Hero() {
  return (
    <>
      <figure className="position-relative hero-img">
        <img
          src={`${process.env.REACT_APP_BUCKET_URL}/assets/Glen headshot cropped_Edit1.png`}
          alt="IGNORE"
          className="img-fluid "
        />
        <figcaption className="hero__quote">
          <h1 className="hero__font">
            There is nothing more fulfilling than working on yourself.
          </h1>
          <a
            href="https://calendly.com/glen-coaching/strategy"
            target="_blank"
            rel="noreferrer"
          >
            <button className="btn__cta">
              Schedule a call&nbsp;&nbsp;&rarr;
            </button>
          </a>
        </figcaption>
      </figure>
    </>
  );
}

export default Hero;
