import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./navigation.css";
import { Link } from "react-router-dom";
function Navigation() {
  return (
    <Navbar className="nav-color" expand="lg">
      <Container className="nav-padd">
        <Navbar.Brand
          href="/"
          className="d-flex align-items-center justify-content-center"
        >
          <img
            alt=""
            src={`${process.env.REACT_APP_BUCKET_URL}/assets/Alight-logo-28b485.png`}
            className="d-inline-block align-top logo-img"
          />{" "}
          <h2 className="heading">Alight Coaching</h2>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
            <NavDropdown title="Offerings" id="basic-nav-dropdown">
              <NavDropdown.Item>
                <Link to="/offerings">Behaviour Change</Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/offerings">
                  Finding Identity, Direction, Purpose
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/offerings">Personal & Spiritual Development</Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/offerings">
                  Emotionally Intelligent Communication
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/offerings">Managing Life Transitions</Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/offerings">Developing a Morning Routine</Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/offerings">Dealing With Stress and Uncertainty</Link>
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link>
              <Link to="/about">About</Link>
            </Nav.Link>
            {/* <Nav.Link>
              <Link to="/work">Working With Me</Link>
            </Nav.Link> */}
            <Nav.Link href="#link">
              <a
                href="https://glencallahan.substack.com/"
                target="_blank"
                rel="noreferrer"
              >
                Blog
              </a>
            </Nav.Link>
            <Nav.Link href="#link">
              <a
                href="https://glen-callahan-self-development-training.teachable.com/"
                target="_blank"
                rel="noreferrer"
              >
                Courses
              </a>
            </Nav.Link>

            <Nav.Link href="#link">
              <a
                href="https://calendly.com/glen-coaching/strategy"
                target="_blank"
                rel="noreferrer"
              >
                <button className="nav__button">Click for Consultation</button>
              </a>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
