import userData from "../../constants/timelineData";
import { Container } from "react-bootstrap";
import "./about.css";

const About = () => {
  return (
    <>
      <section className="experience__container">
        <img
          className="back__banner"
          src={`${process.env.REACT_APP_BUCKET_URL}/assets/Screenshot 2023-01-28 at 10.40.21 AM.png`}
          alt="banner"
        />
        <Container className="experience">
          {userData.experience.map((i, index) => {
            return (
              <>
                <div className="about__card" key={index}>
                  <h2 className="year">{i.year} </h2>
                  <h4 className="card__title">{i.title}</h4>
                  <h6 className="card__company">{i.company}</h6>
                  <p className="card__desc">{i.desc}</p>
                  <div className="dot__fixed">
                    <div className="dot__animate"></div>
                  </div>
                </div>
                {userData.experience.length - 1 !== index && (
                  <div className="line__container">
                    <div className="line"></div>
                  </div>
                )}
              </>
            );
          })}
        </Container>
      </section>
    </>
  );
};

export default About;
