import React from "react";
import SimpleSlider from "../Testimonials/testimonial";
import Card from "./Card";
import Hero from "./Hero";
import MainContent from "./MainContent";

import Work from "./Work";

function Home() {
  return (
    <div>
      <Hero />
      <MainContent />
      <Card />
      <Work />
      <SimpleSlider />
    </div>
  );
}

export default Home;
