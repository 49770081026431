import React from "react";
import { Container } from "react-bootstrap";
import "./work.css";

function Work() {
  return (
    <div className="content-body">
      <div className="mx-5">
        <div className=" work-section ">
          <h1 id="working-with-me" className="h1-work">
            Working With Me
          </h1>
          <p>
            Once we decide to work together, our initial engagement is three
            months. Discussions are based around a question-answer dynamic.
            Together we assess your present state – external and internal. You
            decide on the person you want to become, and the developmental
            changes required to get there.
          </p>
          <p>
            I will support your efforts, serve as an accountability partner, and
            celebrate your wins. <br /> Combining the ancient self-development
            philosophy of India with contemporary evidence based coaching
            models, you’ll be transformed. It’s a unique East meets West
            approach.
          </p>

          <h4 className="title-investment mb-5 mt-5">
            Your Investment Includes:
          </h4>
        </div>
        <Container>
          <div className="mx-1 ">
            <div className=" d-flex justify-content-between align-items-center con-right ">
              <div className=" px-2 content__width">
                <h2 className="sub-head">Bi-Weekly Coaching Sessions</h2>
                <ul>
                  <li>
                    We explore your nature, what’s important to you, and why.
                  </li>
                  <li>
                    We set the vision and goals for your life and your
                    development.
                  </li>
                  <li>
                    I’ll invite your self-reflection on your mindset and
                    thinking, reflect back to you what I have heard, and
                    challenge your assumptions.
                  </li>
                  <li>
                    We explore actions that will take you closer to your goals.
                  </li>
                </ul>
              </div>
              <div className="work__img d-flex align-items-center justify-content-end">
                <img
                  src={`${process.env.REACT_APP_BUCKET_URL}/assets/3_DSCF6905.jpeg`}
                  alt="work-img"
                  className="invest-section-img"
                />
              </div>
            </div>
            <hr />
            <div className="d-flex justify-content-between align-items-center con-left">
              <div className="work__img d-flex align-items-center justify-content-start">
                <img
                  src={`${process.env.REACT_APP_BUCKET_URL}/assets/pexels-albin-biju-6717650.jpeg`}
                  alt="work-img-2"
                  className="invest-section-img"
                />
              </div>

              <div className="px-2 content__width">
                <h2 className="sub-head"> Access to Alight Connect.</h2>
                <ul>
                  <li>
                    Alight Connect is an online record of your coaching and
                    developmental journeys.
                  </li>
                  <li>
                    It includes sessions notes, exercises, reflection
                    worksheets, goals, actions, and metrics.
                  </li>
                  <li>
                    This provides structure and accountability, and allows you
                    to monitor your progress over time.
                  </li>
                </ul>
              </div>
            </div>
            <hr />
            <div className="d-flex justify-content-between align-items-center con-right">
              <div className="px-2 content__width">
                <h2 className="sub-head">Offline Reflections</h2>
                <ul>
                  <li>
                    Based on our discussions, I may assign you exercises to
                    complete between coaching sessions.
                  </li>
                  <li>
                    These include personality assessments, self-reflection
                    exercises, and
                  </li>
                  <li>
                    Working with these is optional, but recommended to help with
                    processing your goals and insights
                  </li>
                </ul>
              </div>
              <div className="work__img d-flex align-items-center justify-content-end">
                <img
                  src={`${process.env.REACT_APP_BUCKET_URL}/assets/3_DSCF6890.jpeg`}
                  alt="work-img"
                  className="invest-section-img offline-image"
                />
              </div>
            </div>
            <hr />
            <div className="d-flex justify-content-between align-items-center con-left">
              <div className="work__img d-flex align-items-center justify-content-start">
                <img
                  src={`${process.env.REACT_APP_BUCKET_URL}/assets/quentin-menini-K6MkxFU0Dyw-unsplash.jpeg`}
                  alt="work-img-2"
                  className="invest-section-img"
                />
              </div>

              <div className="px-2 content__width">
                <h2 className="sub-head"> Practical Exercises</h2>
                <ul>
                  <li>
                    Practices are developed and maintained in order to help you
                    integrate your insights.
                  </li>
                  <li>
                    They provide support for the mindset and behavior change
                    that you want to create.
                  </li>
                  <li>
                    Together we develop strategies to work towards your goals in
                    daily life.
                  </li>
                </ul>
              </div>
            </div>
            <hr />
            <div className="d-flex justify-content-between align-items-center con-right">
              <div className=" px-2 content__width">
                <h2 className="sub-head">Access to Alight Reflect</h2>
                <ul>
                  <li>
                    Alight Reflect is a video library of theory and practical
                    content.
                  </li>
                  <li>
                    It supports your understanding of the work you are doing,
                    and offers some step-by-step methods to apply it.
                  </li>
                  <li>
                    It offers self-directed learning on topics such as:
                    <ul>
                      <li>Emotionally-intelligent communication</li>
                      <li>Behavior change</li>
                      <li> Dealing with stress & uncertainty</li>
                      <li>Ideal-setting</li>
                      <li>Navigating the mind-intellect system</li>
                      <li>Vedic wisdom and self-development</li>
                      <li>Defining mature spirituality</li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="work__img d-flex align-items-center justify-content-end">
                <img
                  src={`${process.env.REACT_APP_BUCKET_URL}/assets/4_DSCF6911.jpeg`}
                  alt="work-img"
                  className="invest-section-img"
                />
              </div>
            </div>
            <hr />
            <div className="d-flex justify-content-between align-items-center con-left">
              <div className="work__img d-flex align-items-center justify-content-start">
                <img
                  src={`${process.env.REACT_APP_BUCKET_URL}/assets/pexels-cottonbro-studio-3202235.jpeg`}
                  alt="work-img-2"
                  className="invest-section-img"
                />
              </div>

              <div className="px-2 content__width">
                <h2 className="sub-head"> Email Availability</h2>
                <ul>
                  <li>48-hour turnaround.</li>
                  <li>
                    Send your follow-up questions, thoughts, reflections, etc
                    for my feedback.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default Work;
