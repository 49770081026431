import React from "react";
import { Container } from "react-bootstrap";
import classes from "./offerings.module.css";

function Offerings() {
  const offerings = [
    {
      title: "Behaviour Change",
      subHeading: "Why is change is difficult?",
      subInfo:
        "The mind naturally resists behaviour changes, even when we know the benefit. This resistance comes from established beliefs and patterns of thought.",
      subList: [
        "Setting goals for positive habits",
        "Identifying counter-productive habits and the underlying beliefs that drive them",
        "Developing strategies to challenge old beliefs and strengthen healthy new ones",
      ],
      img: process.env.REACT_APP_BUCKET_URL + "/assets/4_DSCF7070.jpeg",
    },
    {
      title: "Finding Identity, Direction, Purpose",
      subHeading: "Who am I?",
      subInfo:
        "Identity, direction, and purpose are vital elements of well-being. Without them we feel lost and uninspired in our own lives.",
      subList: [
        "What inspires your interest and desire",
        "Your natural talents, core values, and where you fit into this world.",
        "How you can use your unique nature to achieve your personal and developmental goals.",
        "Discover the fundamental life activity that satisfies your reason for being.",
      ],
      img:
        process.env.REACT_APP_BUCKET_URL +
        "/assets/pexels-mark-neal-2009968.jpeg",
    },
    {
      title: "Personal & Spiritual Development",
      subHeading: "There is nothing more rewarding than working on yourself",
      subInfo:
        "Personal development means creating a more mature self, based on higher values. Spiritual development means exploring your true Identity. It is not created, but is the very core of your personality. Development allows you to handle more complex situations and relationships with ease. You develop emotional resilience, and become comfortable in the uncomfortable.",
      subList: [
        "Identifying your growth edges: the personality traits that cause life’s situations to be challenging",
        `Developing a vision and SMART goals for who you want to become`,
        "Integrating developmental practices into your daily life (physical, emotional, intellectual, social, spiritual)",
      ],
      img: process.env.REACT_APP_BUCKET_URL + "/assets/3_DSCF6982.jpeg",
    },
    {
      title: "Emotionally Intelligent Communication",
      subHeading: "Making hard conversations easy. OK, easier...",
      subInfo:
        "The key factor for determining whether relationships are healthy or unhealthy is communication. Our ego’s demands and defences get in the way of fulfilling our genuine relationship needs.",
      subList: [
        "Identifying the relationships and circumstances that need improved communication",
        "Identifying your genuine needs and where they are being unmet",
        "Creating structured dialogues within your relationships that enable active listening, clear communication, resilience, and compassion",
        "Ways to take accountability for your own emotions, set healthy boundaries, and make reasonable requests in your relationships",
      ],
      img:
        process.env.REACT_APP_BUCKET_URL +
        "/assets/pexels-alesia-kozik-6064692.jpeg",
    },
    {
      title: "Managing Life Transitions",
      subHeading: "The test of your character is how you walk through the fire",
      subInfo:
        "At all stages of life we are faced with transition. Whether you’re leaving home for the first time, welcoming a child into the family, or saying goodbye to a loved one, adapting to a new reality is challenging.",
      subList: [
        "Identifying your core needs, and ways that you can meet them",
        "SMART Goal-setting – planning who you want to become through this transition",
        "Planning a mindset roadmap – taking charge of the attitude you will adopt to face the change",
        "Identifying supporting behaviors and people",
      ],
      img:
        process.env.REACT_APP_BUCKET_URL +
        "/assets/Copy of V2_colors_DSCF6858_A.jpeg",
    },
    {
      title: "Developing a Morning Routine",
      subHeading: "Practice makes better",
      subInfo:
        "Discipline is freedom. A stable morning routine sets the tone for the day, putting you in the mindset for productivity and peace.        However, your morning routine needs to cater to your specific personality – one size does not fit all.",
      subList: [
        "Assess your nature",
        "Set SMART goals for your morning routine",
        "Explore and integrate the elements of a routine at each level.",
        "Emotional: Inspiring your feelings towards your higher goals",
        "Intellectual: Self-reflection and meditative practices",
        "Physical: Exercise to serve the needs of body, brain, and mind",
      ],
      img:
        process.env.REACT_APP_BUCKET_URL +
        "/assets/pexels-simon-berger-1266810.jpeg",
    },
    {
      title: "Dealing with Stress and Uncertainty",
      subHeading:
        "The world cannot disturb you. Only your relationship with it.",
      subInfo:
        "Effectively managing stress is a skill we need to learn, like any other activity. Changing perspective changes our mind, bringing greater peace and clarity. However, managing stress alone is not a long-term strategy. Its causes must be understood and eliminated.",
      subList: [
        "Simple, practical tools for re-framing life’s experiences",
        "Uncover and eliminate causes of stress",
        "Self-reflection & Goal-setting",
        "Daily practices for emotional resilience",
      ],
      img:
        process.env.REACT_APP_BUCKET_URL +
        "/assets/pexels-simon-berger-1266810.jpeg",
    },
  ];

  return (
    <div className={classes.offering__background}>
      <div className={classes.offering__section}>
        <h1 id="offerings" className={classes.offering__title}>
          Offerings
        </h1>
      </div>
      <Container>
        {offerings.map((offering, index) => (
          <div key={index}>
            <div className={classes.offering__container}>
              <div className={`${classes.offering__width}`}>
                <h2 className={classes.offering__heading}>{offering.title}</h2>
                <p className={classes.offering__italic}>
                  {offering.subHeading}
                </p>
                <p className={classes.offering__info}>{offering.subInfo}</p>

                <ul>
                  {offering.subList.map((list) => (
                    <li key={list}>{list}</li>
                  ))}
                </ul>
              </div>

              <div className={classes.offering__img}>
                <img src={offering.img} alt={offering.title} width="100%" />
              </div>
            </div>
            <hr />
          </div>
        ))}
      </Container>
    </div>
  );
}

export default Offerings;
