import React from "react";
import "./footer.css";
import {
  AiFillLinkedin,
  AiFillInstagram,
  AiFillFacebook,
  AiFillYoutube,
} from "react-icons/ai";

function Footer() {
  return (
    <>
      <div className="footer-main">
        <div>
          <h2 className="footer-title">Alight Coaching</h2>
        </div>
        <div className="footer-images ">
          <a
            href="https://www.linkedin.com/in/glen-callahan/"
            target="_blank"
            rel="noreferrer"
          >
            <AiFillLinkedin className="footer-icon" />
          </a>

          <a
            href=" https://www.instagram.com/alightwellness/"
            target="_blank"
            rel="noreferrer"
          >
            <AiFillInstagram className="footer-icon" />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=100084948637603"
            target="_blank"
            rel="noreferrer"
          >
            <AiFillFacebook className="footer-icon" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCAY2I5Qvr_09H4DnUeHb2Dg"
            target="_blank"
            rel="noreferrer"
          >
            <AiFillYoutube className="footer-icon" />
          </a>
        </div>
        <div className="footer-last">
          <p>© 2023 Alight Coaching</p>
          <p>Developed by </p>
        </div>
      </div>
    </>
  );
}

export default Footer;
