import React from "react";
import { Container } from "react-bootstrap";

import "./maincontent.css";

function MainContent() {
  return (
    <div className="full-section">
      <video
        className="back__video"
        controls={false}
        autoPlay
        muted
        loop="infinite"
      >
        <source
          src={`${process.env.REACT_APP_BUCKET_URL}/assets/WhatsApp Video 2023-01-28 at 11.15.48 AM.mp4`}
          type="video/mp4"
        />
      </video>
      <Container>
        <div className="d-flex justify-content-between align-items-center main-container">
          <div className="img__div">
            <img
              className="side-image"
              src={`${process.env.REACT_APP_BUCKET_URL}/assets/4_DSCF6793.jpeg`}
              alt="Lago di Braies"
            />
          </div>
          <div className="hero-content">
            <h1>Perfection is a myth.</h1>
            <br />
            <p>
              We will always face challenges. It could be letting go of bad
              habits and creating new ones, managing relationships, facing life
              transitions and loss, or just seeking meaning and purpose in life.
              Do you want to learn how to manage these kinds of challenges? And
              importantly, are you willing to show up and put in the effort
              required to get there?
            </p>
            {/* <p>
              My name is Glen Callahan and I am a developmental coach. I spent
              eight years in India under Swami A. Parthasarathy, studying and
              practicing Vedic self-development. I spent eight further years
              teaching and guiding self-development. I have a coaching
              certificate accredited by the International Coaching Federation.
              And I have 18 years of walking the path of self- development
              myself.
            </p>
            <p>
              There is nothing more rewarding than working on your self. And
              what I want for me, I want for you. I’ve discovered my purpose and
              I’m making it my service to you. If you want to learn more,
              contact me for your free strategy session.
            </p> */}
            <a
              href="https://calendly.com/glen-coaching/strategy"
              target="_blank"
              rel="noreferrer"
            >
              <button>Click for Consultation</button>
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default MainContent;
