import React, { Component } from "react";
import Slider from "react-slick";
import "./testimonial.css";

let testimonials = [
  {
    quote:
      " “I have definitely benefited from my study and practice. The stated objective of this study is the achievement of mental peace. The skills of objectivity and intellectual analysis that Vedanta teaches are invaluable” ",
    name: " - Paul M  ",
    title: "Business Owner",
    location: "San Diego, California",
  },
  {
    quote:
      " “Working with Glen is a game changer. It is a life changing experience” ",
    name: "  - Shila N  ",
    title: "Bi-lingual Counsellor",
    location: "Brooklyn, New York",
  },
  {
    quote:
      " “Glen's methods almost remove you personally from the stress of impeding life decisions to have a more introspective point of view that is not fueled by fear and uncertainty” ",
    name: "  - Kerry G ",
    title: "IT Entrepreneur",
    location: "Santa Monica, California",
  },
  {
    quote:
      " “Prior to one on one coaching, I had done the behaviour change course with Glen. This was phenomenal in helping me identify personal growth areas as well as underlying beliefs that needed challenging” ",
    name: " - Jayshree S ",
    title: " Artist, Teacher",
    location: "Durban, South Africa",
  },
];

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      arrows: false,
    };
    return (
      <div className="mb-4">
        <h1 className="testimonial__heading"> Testimonial</h1>
        <Slider {...settings} className="testimonial__slider">
          {testimonials.map((t) => {
            return (
              <>
                <div className="testimonial__card">
                  <p className="testimonial__content">{t.quote}</p>
                  <br />
                  <p className="test-text">{t.name}</p>
                  <p className="test-text">{t.title}</p>
                  <p className="test-text">{t.location}</p>
                </div>
              </>
            );
          })}
        </Slider>
        <br />
        <br />
      </div>
    );
  }
}
