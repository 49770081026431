const userData = {
  experience: [
    {
      title: "Founder",
      company: "Alight Coaching, New Zealand",
      year: "2022 ",
      desc: "Developmental coaching integrating east and west",
    },
    {
      title: "Education",
      company: "Fielding Graduate University, California",
      year: "2020 ",
      desc: "Certificate in Evidence-based Coaching (ICF-accredited)",
    },
    {
      title: "Co-Founder & Teacher",
      company: "Vedanta Institute LA, Los Angeles",
      year: "2014 ",
      desc: "Classes and courses on Vedic self-development.",
    },
    {
      title: "Philosophy & Self-Development",
      company: "Vedanta Academy, India",
      year: "2006 ",
      desc: "Studied under Swami A. Parthasarathy. Diploma in Vedanta Philosophy ",
    },
    {
      title: "Work",
      company: "Experience across industries, sectors and roles",
      year: "2000",
      desc: "Worked in various industries such as dairy, pharmaceutical and human resources",
    },
    {
      title: "Education",
      company: "Massey University, New Zealand",
      year: "1999",
      desc: "Masters in Chemical Technology",
    },
  ],
  // resumeUrl:
  //   'https://drive.google.com/file/d/1tFh2Py98Yk44fuGY4NmV5juk8IWgMCNc/view?usp=sharing',
  // socialLinks: {
  //   instagram: 'https://instagram.com/himanshu1696',
  //   linkedin: 'https://linkedin.com/in/himanshu-borole/',
  //   github: 'https://github.com/hborole',
  //   facebook: 'https://www.facebook.com/himanshu.borole',
  // },
};

export default userData;
