import React from "react";
import "./card.css";
import { useNavigate } from "react-router-dom";

function Card() {
  const navigate = useNavigate();
  return (
    <>
      <h1 className="offerings-title">Offerings</h1>

      <div className="main-card">
        <div className="card" onClick={() => navigate("/offerings")}>
          <img
            className="card-image"
            src={`${process.env.REACT_APP_BUCKET_URL}/assets/marek-piwnicki-ycHhgGJuvhE-unsplash.jpeg`}
            alt="Lago di Braies"
          />
          {/* <!-- A div with card__details class to hold the details in the card  --> */}
          <div className="card__details">
            {/* <!-- A div with name class for the name of the card --> */}
            <div className="name">Dealing With Stress and Uncertainty</div>

            <p className="card__para">
              Effectively managing stress is a skill we need to learn, like any
              other activity. Changing perspective changes our mind, bringing
              greater peace and clarity.
            </p>
            <button className="card__button">Learn more</button>
          </div>
        </div>

        <div className="card" onClick={() => navigate("/offerings")}>
          <img
            className="card-image"
            src={`${process.env.REACT_APP_BUCKET_URL}/assets/photo-1536323760109-ca8c07450053.jpeg`}
            alt="Lago di Braies"
          />
          {/* <!-- A div with card__details class to hold the details in the card  --> */}
          <div className="card__details">
            {/* <!-- A div with name class for the name of the card --> */}
            <div className="name">Finding Identity, Direction, Purpose</div>

            <p className="card__para">
              Identity, direction, and purpose are vital elements of well-being.
              Without them we feel lost and uninspired in our own lives.
            </p>
            <button className="card__button">Learn more</button>
          </div>
        </div>

        <div className="card" onClick={() => navigate("/offerings")}>
          <img
            className="card-image"
            src={`${process.env.REACT_APP_BUCKET_URL}/assets/photo-1668531426021-584e44a010cd.jpeg`}
            alt="Lago di Braies"
          />
          {/* <!-- A div with card__details class to hold the details in the card  --> */}
          <div className="card__details">
            {/* <!-- A div with name class for the name of the card --> */}
            <div className="name">Personal & Spiritual Development</div>

            <p className="card__para">
              Personal development means creating a more mature self, based on
              higher values. Spiritual development means exploring your true
              Identity. It is not created, but is the very core of your
              personality.
            </p>
            <button className="card__button">Learn more</button>
          </div>
        </div>

        <div className="card" onClick={() => navigate("/offerings")}>
          <img
            className="card-image"
            src={`${process.env.REACT_APP_BUCKET_URL}/assets/communication.jpeg`}
            alt="Lago di Braies"
          />
          {/* <!-- A div with card__details class to hold the details in the card  --> */}
          <div className="card__details">
            {/* <!-- A div with name class for the name of the card --> */}
            <div className="name">Emotionally Intelligent Communication</div>

            <p className="card__para">
              The key factor for determining whether relationships are healthy
              or unhelthy is communication. Our ego’s demands and defences get
              in the way of fulfilling our genuine relationship needs.
            </p>
            <button className="card__button">Learn more</button>
          </div>
        </div>

        <div className="card" onClick={() => navigate("/offerings")}>
          <img
            className="card-image"
            src={`${process.env.REACT_APP_BUCKET_URL}/assets/photo-1668125786875-ce4c80b61f0b.jpeg`}
            alt="Lago di Braies"
          />
          {/* <!-- A div with card__details class to hold the details in the card  --> */}
          <div className="card__details">
            {/* <!-- A div with name class for the name of the card --> */}
            <div className="name">Managing Life Transitions</div>

            <p className="card__para">
              At all stages of life we are faced with transition. Whether you’re
              leaving home for the fiorst time, welcoming a child into the
              family, or saying goodbye to a loved one, adapting to a new
              reality is challenging.
            </p>
            <button className="card__button">Learn more</button>
          </div>
        </div>

        <div className="card" onClick={() => navigate("/offerings")}>
          <img
            className="card-image"
            src={`${process.env.REACT_APP_BUCKET_URL}/assets/morning.jpeg`}
            alt="Lago di Braies"
          />
          {/* <!-- A div with card__details class to hold the details in the card  --> */}
          <div className="card__details">
            {/* <!-- A div with name class for the name of the card --> */}
            <div className="name">Developing a Morning Routine</div>

            <p className="card__para">
              Discipline is hard. But if nothing changes, nothing changes.
              Having a stable morning routine is vital for setting the tone for
              the day. It puts you in the mindset needed to achieve the
              incremental changes towards your goals.
            </p>
            <button className="card__button">Learn more</button>
          </div>
        </div>
        {/* New Card */}
        <div className="card" onClick={() => navigate("/offerings")}>
          <img
            className="card-image"
            src={`${process.env.REACT_APP_BUCKET_URL}/assets/photo-1668524140550-09b80b3cddb1.jpeg`}
            alt="Lago di Braies"
          />
          <div className="card__details">
            <div className="name">Behaviour Change</div>
            <p className="card__para">
              The mind naturally resists changes in behaviour, even when we know
              they benefit us. This resistance comes from established beliefs
              and patterns of thought that remain obscured.
            </p>
            <button className="card__button">Learn more</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Card;
