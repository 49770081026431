import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";
import Home from "./components/Home/Home";
import { Route, Routes } from "react-router-dom";
import About from "./components/About/About";
import Navigation from "./components/Navigation/Navigation";
import Offerings from "./components/Offerings/Offerings";
import Work from "./components/Home/Work";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <>
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/offerings" element={<Offerings />} />
        <Route path="/work" element={<Work />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
